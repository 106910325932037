//-----------------------------------------------------------------------------------------------------------
// File: /src/components/header/menu-button/MenuButton.tsx
//-----------------------------------------------------------------------------------------------------------

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../../actors/auth-client";
import "./MenuButton.css";

//-----------------------------------------------------------------------------------------------------------

export function MenuButton({ name }: { name: string }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dropdown is-right ${isOpen ? "is-active" : ""}`}>
      <div className="dropdown-trigger">
        <button
          className="button is-small is-ghost has-text-black button header-button is-uppercase"
          aria-haspopup="true"
          aria-controls="dropdown-menu6"
          onClick={toggleDropdown}
        >
          <span className="is-small">Menu</span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu6" role="menu">
        <div className="dropdown-content single-border">
          <div className="dropdown-item">
            <span className="is-small has-text-primary">{name}</span>
          </div>
          <hr className="dropdown-divider" />
          <a
            href="#"
            className={`dropdown-item ${currentRoute === "/" ? "is-active" : ""}`}
            onClick={() => navigate("/")}
          >
            Dashboard
          </a>
          <a
            href="#"
            className={`dropdown-item ${currentRoute === "/users" ? "is-active" : ""}`}
            onClick={() => navigate("/users")}
          >
            Users
          </a>
          <a
            href="#"
            className={`dropdown-item ${currentRoute === "/projects" ? "is-active" : ""}`}
            onClick={() => navigate("/projects")}
          >
            Projects
          </a>
          <a
            href="#"
            className={`dropdown-item ${currentRoute === "/kanban" ? "is-active" : ""}`}
            onClick={() => navigate("/kanban")}
          >
            Kanban Board
          </a>
          <hr className="dropdown-divider" />
          <a
            href="#"
            className={`dropdown-item ${currentRoute === "/change-password" ? "is-active" : ""}`}
            onClick={() => navigate("/change-password")}
          >
            Change Password
          </a>
          <hr className="dropdown-divider" />
          <a href="#" className="dropdown-item" onClick={logout}>
            Logout
          </a>
        </div>
      </div>
    </div>
  );
}

//-----------------------------------------------------------------------------------------------------------

export function MenuButtonWideScreen({ name }: { name: string }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dropdown is-right ${isOpen ? "is-active" : ""}`}>
      <div className="dropdown-trigger">
        <button
          className="button is-small is-ghost has-text-black button header-button is-uppercase"
          aria-haspopup="true"
          aria-controls="dropdown-menu6"
          onClick={toggleDropdown}
        >
          <span className="is-small">{name}</span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu6" role="menu">
        <div className="dropdown-content single-border">
          <a
            href="#"
            className={`dropdown-item ${currentRoute === "/" ? "is-active" : ""}`}
            onClick={() => navigate("/")}
          >
            Dashboard
          </a>
          <a
            href="#"
            className={`dropdown-item ${currentRoute === "/users" ? "is-active" : ""}`}
            onClick={() => navigate("/users")}
          >
            Users
          </a>
          <hr className="dropdown-divider" />
          <a
            href="#"
            className={`dropdown-item ${currentRoute === "/change-password" ? "is-active" : ""}`}
            onClick={() => navigate("/change-password")}
          >
            Change Password
          </a>
          <hr className="dropdown-divider" />
          <a href="#" className="dropdown-item" onClick={logout}>
            Logout
          </a>
        </div>
      </div>
    </div>
  );
}

//-----------------------------------------------------------------------------------------------------------
