//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/user-client/states/authorised.ts
//-----------------------------------------------------------------------------------------------------------

import { sendMessage, Message } from "../../../utils/modulo-plus";
import {
  UserClientData_UnAuthorised,
  UserClientData_Authorised,
  UserClientData_Ready,
  UserClientMessages_UnAuthorised,
  UserClientMessages_Authorised,
  UserClientMessages_Ready,
  UserClientName,
  UserServiceName,
} from "../def";
import { withSessionToken } from "../../auth-client";

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: load-users
//-----------------------------------------------------------------------------------------------------------

export async function onLoadUsers(messageId: string) {
  // TODO: Add validations here. If they fail, throw an error.

  sendMessage(
    withSessionToken({
      id: messageId,
      name: UserClientMessages_Authorised.LoadUsers,
      payload: {},
      from: UserClientName,
      to: UserServiceName,
      confidential: false,
    }),
  );
}

export async function onLoadUsersSuccess(
  data: UserClientData_Ready,
  messageId: string,
): Promise<UserClientData_Ready> {
  // TODO: Add any post-processing of data if required.
  return data;
}

export async function onLoadUsersFailure(
  error: string,
  messageId: string,
): Promise<string> {
  // TODO: Add any post-processing of the error if required.
  return error;
}

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: auth-logout
//-----------------------------------------------------------------------------------------------------------

export async function onAuthLogout(messageId: string) {
  // TODO: Add validations here. If they fail, throw an error.
  return {};
}

//-----------------------------------------------------------------------------------------------------------
