// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: /src/components/header/home-button/HomeButton.css */

.home-logo-a {
  margin-top: 5px;
}

.home-logo-a-wide-screen {
  margin-right: 10px;
}

.home-logo {
  width: 30px;
  height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/home-button/HomeButton.css"],"names":[],"mappings":"AAAA,4DAA4D;;AAE5D;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["/* File: /src/components/header/home-button/HomeButton.css */\n\n.home-logo-a {\n  margin-top: 5px;\n}\n\n.home-logo-a-wide-screen {\n  margin-right: 10px;\n}\n\n.home-logo {\n  width: 30px;\n  height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
