//-----------------------------------------------------------------------------------------------------------
// File: /src/components/my-title/MyTitle.tsx
//-----------------------------------------------------------------------------------------------------------

import "./MyTitle.css";

//-----------------------------------------------------------------------------------------------------------

interface MyTitleProps {
  title: string;
}

//-----------------------------------------------------------------------------------------------------------

function MyTitle({ title }: MyTitleProps) {
  return <h3 className="title is-6 has-text-primary">{title}</h3>;
}

//-----------------------------------------------------------------------------------------------------------

export default MyTitle;

//-----------------------------------------------------------------------------------------------------------
