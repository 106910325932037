//-----------------------------------------------------------------------------------------------------------
// File: /src/screens/change-password/ChangePassword.tsx
//-----------------------------------------------------------------------------------------------------------

import { useNavigate } from "react-router-dom";
import "./ChangePassword.css";
import { useEffect, useState } from "react";
import {
  AuthClientStates,
  changePassword,
  useAuthClientError,
  useAuthClientState,
  useIsAuthClientState_ChangePassword_WIP,
} from "../../actors/auth-client";
import Header from "../../components/header/Header";
import { isStateChangeFrom } from "../../utils/modulo-utils";
import MyButton from "../../components/my-button/MyButton";
import MyInput from "../../components/my-input/MyInput";
import Loader from "../../components/loader/Loader";
import MyTitle from "../../components/my-title/MyTitle";
import { z } from "zod";
import ErrorMessage from "../../components/error-message/ErrorMessage";

//-----------------------------------------------------------------------------------------------------------

const changePasswordSchema = z.object({
  currentPassword: z
    .string()
    .min(8, "Current password must be at least 8 characters long"),
  newPassword: z
    .string()
    .min(8, "New password must be at least 8 characters long"),
  repeatNewPassword: z
    .string()
    .min(8, "Repeat new password must be at least 8 characters long"),
});

//-----------------------------------------------------------------------------------------------------------

function ChangePassword() {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const isAuthClientChangePasswordWIP =
    useIsAuthClientState_ChangePassword_WIP();
  const { state, previousState } = useAuthClientState();
  const authClientError = useAuthClientError();
  const [validationError, setValidationError] = useState<string | null>();

  useEffect(() => {
    if (
      isStateChangeFrom(previousState)
        .to(state)
        .equals(AuthClientStates.ChangePassword_WIP)
        .to(AuthClientStates.Authorised)
    ) {
      if (authClientError)
        alert(`Could not change password, error: ${authClientError}`);
      else {
        alert("Password change completed and authenticated!");
        navigate("/");
      }
    }
  }, [state, previousState, authClientError]);

  const handleChangePassword = () => {
    const validationResult = changePasswordSchema.safeParse({
      currentPassword,
      newPassword,
      repeatNewPassword,
    });
    if (!validationResult.success) {
      const errorMessages = validationResult.error.errors.map(
        (err: any) => err.message,
      );
      setValidationError(errorMessages[0]);
      return;
    }
    if (newPassword !== repeatNewPassword) {
      setValidationError("New passwords do not match!");
      return;
    }
    if (newPassword.length < 6) {
      alert(
        "Please enter a valid password (should be 6 characters or more in length)",
      );
      return;
    }
    changePassword(currentPassword, newPassword);
  };

  return (
    <>
      <Header />
      <div className="container is-fluid full-size-container">
        <div className="card my-card single-border">
          <div className="card-content">
            <MyTitle title="Change Password" />
            <MyInput
              type="password"
              label="Current Password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={setCurrentPassword}
              required
              disabled={isAuthClientChangePasswordWIP}
            />

            <MyInput
              type="password"
              label="New Password"
              placeholder="New Password"
              value={newPassword}
              onChange={setNewPassword}
              required
              disabled={isAuthClientChangePasswordWIP}
            />

            <MyInput
              type="password"
              label="Repeat New Password"
              placeholder="Repeat New Password"
              value={repeatNewPassword}
              onChange={setRepeatNewPassword}
              required
              disabled={isAuthClientChangePasswordWIP}
            />

            {validationError && (
              <ErrorMessage text={validationError} isFullWidth />
            )}

            {!isAuthClientChangePasswordWIP && (
              <MyButton
                name="Change Password"
                onClick={handleChangePassword}
                isMain
                isSmall
                isFullWidth
                customClasses="button my-margin-top"
              />
            )}

            {isAuthClientChangePasswordWIP && (
              <Loader text="Changing password ..." />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

//-----------------------------------------------------------------------------------------------------------

export default ChangePassword;

//-----------------------------------------------------------------------------------------------------------
