//-----------------------------------------------------------------------------------------------------------
// File: /src/components/input/MyInput.tsx
//-----------------------------------------------------------------------------------------------------------

import "./MyInput.css";

//-----------------------------------------------------------------------------------------------------------

interface MyInputProps {
  type: string;
  label?: string;
  placeholder?: string;
  customClasses?: string;
  onChange: (value: string) => void;
  value: string;
  required?: boolean;
  disabled?: boolean;
  autocomplete?: string;
}

//-----------------------------------------------------------------------------------------------------------

function MyInput({
  type,
  label,
  placeholder,
  customClasses = "",
  onChange,
  value,
  required = false,
  disabled = false,
  autocomplete = "off",
}: MyInputProps) {
  return (
    <div className={`field ${customClasses}`}>
      {label && (
        <label className="label is-size-7 has-text-grey">{`${label}${required ? " *" : ""}`}</label>
      )}
      <div className="control">
        <input
          className="input is-size-7"
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          required={required}
          disabled={disabled}
          autoComplete={autocomplete}
          data-testid="my-input" // Add this line
        />
      </div>
    </div>
  );
}

//-----------------------------------------------------------------------------------------------------------

export default MyInput;

//-----------------------------------------------------------------------------------------------------------
