//-----------------------------------------------------------------------------------------------------------
// File: /src/screens/projects/Projects.tsx
//-----------------------------------------------------------------------------------------------------------

import Header from "../../components/header/Header";
import "./Projects.css";

//-----------------------------------------------------------------------------------------------------------

function Projects() {
  return (
    <>
      <Header />
    </>
  );
}

//-----------------------------------------------------------------------------------------------------------

export default Projects;

//-----------------------------------------------------------------------------------------------------------
