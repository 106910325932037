//-----------------------------------------------------------------------------------------------------------
// File: /src/screens/login/Login.tsx
//-----------------------------------------------------------------------------------------------------------

import React, { act, useEffect, useState } from "react";
import "./Login.css";
import {
  login,
  useAuthClientError,
  useIsAuthClientState_Login_WIP,
} from "../../actors/auth-client";
import iRealitiesLogo from "../../assets/iRealitiesLogo.png";
import { z } from "zod";
import MyButton from "../../components/my-button/MyButton";
import MyInput from "../../components/my-input/MyInput";
import Loader from "../../components/loader/Loader"; // Ensure Loader is imported
import ErrorMessage from "../../components/error-message/ErrorMessage";

//-----------------------------------------------------------------------------------------------------------

const loginSchema = z.object({
  loginId: z.string().min(3, "Login ID must be at least 3 characters"),
  password: z.string().min(8, "Password must be at least 8 characters long"),
});

//-----------------------------------------------------------------------------------------------------------

const Login: React.FC = () => {
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [validationError, setValidationError] = useState<string | null>();

  const isLoginWIP = useIsAuthClientState_Login_WIP();
  const authClientError = useAuthClientError();

  const handleLogin = () => {
    const validationResult = loginSchema.safeParse({ loginId, password });
    if (!validationResult.success) {
      const errorMessages = validationResult.error.errors.map(
        (err: any) => err.message,
      );
      setValidationError(errorMessages[0]);
      return;
    }
    setValidationError(null);
    login(loginId, password);
  };

  return (
    <div className="container is-fluid full-size-container">
      <nav className="card my-card is-align-items-center single-border">
        <p className="card-header-title has-text-weight-light centered-title is-size-5 mt-2 has-text-grey">
          tritiya @ i.Realities
        </p>
        <div className="card-image p-5 mb-2">
          <figure className="image">
            <img
              src={iRealitiesLogo}
              alt="i.Realities Logo"
              className="i-realities-logo is-skeleton"
            />
          </figure>
        </div>
        <div className="login-form-container">
          <MyInput
            type="text"
            label="Login ID"
            placeholder="Login ID"
            value={loginId}
            onChange={setLoginId}
            required
            disabled={isLoginWIP}
            autocomplete="username"
          />
          <MyInput
            type="password"
            label="Password"
            placeholder="Password"
            value={password}
            onChange={setPassword}
            required
            disabled={isLoginWIP}
            autocomplete="current-password"
          />
          {validationError && (
            <ErrorMessage text={validationError} isFullWidth />
          )}
          {authClientError && (
            <ErrorMessage text={authClientError} isFullWidth />
          )}
          {!isLoginWIP && (
            <MyButton name="Login" onClick={handleLogin} isFullWidth isMain />
          )}
          {isLoginWIP && <Loader text="Logging in ..." isFullWidth />}
        </div>{" "}
      </nav>
    </div>
  );
};

//-----------------------------------------------------------------------------------------------------------

export default Login;

//-----------------------------------------------------------------------------------------------------------
