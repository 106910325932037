//-----------------------------------------------------------------------------------------------------------
// File: /src/components/header/Header.tsx
//-----------------------------------------------------------------------------------------------------------

import { useNavigate } from "react-router-dom";
import {
  AuthClientStates,
  useAuthClientData_Authorised,
  useAuthClientError,
  useAuthClientState,
  useIsAuthClientState_Logout_WIP,
} from "../../actors/auth-client";
import "./Header.css";
import { useEffect } from "react";
import { hasReachedState, isStateChangeFrom } from "../../utils/modulo-utils";
import MyButton from "../my-button/MyButton";
import { HomeButton } from "./home-button/HomeButton";
import { MenuButton, MenuButtonWideScreen } from "./menu-button/MenuButton";

//-----------------------------------------------------------------------------------------------------------

function Header() {
  const navigate = useNavigate();
  const authClientAuthorised = useAuthClientData_Authorised();
  const isLogoutWIP = useIsAuthClientState_Logout_WIP();
  const { state, previousState } = useAuthClientState();
  const authClientError = useAuthClientError();

  useEffect(() => {
    if (
      isStateChangeFrom(previousState)
        .to(state)
        .equals(AuthClientStates.Logout_WIP)
        .to(AuthClientStates.Authorised)
    ) {
      alert(`Could not logout, error: ${authClientError}`);
    }

    if (
      hasReachedState(AuthClientStates.UnAuthorised)
        .fromAnyState()
        .values(state, previousState)
    ) {
      navigate("/"); // Navigate to home page on logout
    }
  }, [state, previousState, authClientError, navigate]);

  if (!authClientAuthorised) return <></>;

  return (
    <div className="header has-background-primary">
      <div className="level-left is-hidden-tablet">
        <div className="level-item">
          <HomeButton />
          <MenuButton name={authClientAuthorised.fullName} />
        </div>
      </div>
      <nav className="level is-hidden-mobile" aria-label="desktop navigation">
        <div className="level-left">
          <div className="level-item">
            <HomeButton isWideScreen />
            <div className="level-item">
              <h6 className="subtitle is-6 has-text-black">
                tritiya @ i.Realities
              </h6>
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <MyButton
              name="Kanban Board"
              isSmall
              onClick={() => navigate("/kanban")}
              customClasses="button header-button"
            />
          </div>
          <div className="level-item">
            <MyButton
              name="Projects"
              isSmall
              onClick={() => navigate("/projects")}
              customClasses="button header-button"
            />
          </div>
          <div className="level-item">
            <MenuButtonWideScreen name={authClientAuthorised.fullName} />
          </div>
        </div>
      </nav>
    </div>
  );
}

//-----------------------------------------------------------------------------------------------------------

export default Header;

//-----------------------------------------------------------------------------------------------------------
