//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/dashboard-client/def.ts
//-----------------------------------------------------------------------------------------------------------

// Enums
export enum JobState {
  Ready = "ready",
  WIP = "w-i-p",
  Paused = "paused",
  Done = "done",
}

//-----------------------------------------------------------------------------------------------------------

// Types
export type Project = {
  id: string;
  name: string;
};

export type Job = {
  id: string;
  name: string;
  plannedHours: number;
  project: Project | { id: string; name: string };
  actualHours: number;
  completedPercentage: number;
  state: JobState;
};

export type UserSummary = {
  id: string;
  fullName: string;
  jobs: Array<Job>;
};

//-----------------------------------------------------------------------------------------------------------
// Actor
//-----------------------------------------------------------------------------------------------------------

export const DashboardClientName = "dashboard-client";
export const DashboardServiceName = "dashboard-service";

export enum DashboardClientStates {
  // Default state: un-authorised

  UnAuthorised = "un-authorised",

  Authorised = "authorised",
  FetchDashboard_WIP = "fetch-dashboard-wip",

  Ready = "ready",
}

//-----------------------------------------------------------------------------------------------------------
// State: un-authorised
//-----------------------------------------------------------------------------------------------------------

export const isDashboardClientState_UnAuthorised = (state: string) =>
  [DashboardClientStates.UnAuthorised].includes(state as DashboardClientStates);

export type DashboardClientData_UnAuthorised = {};

export enum DashboardClientMessages_UnAuthorised {
  AuthLogin = "auth-login",
}

//-----------------------------------------------------------------------------------------------------------
// State: authorised
//-----------------------------------------------------------------------------------------------------------

export const isDashboardClientState_Authorised = (state: string) =>
  [
    DashboardClientStates.Authorised,
    DashboardClientStates.FetchDashboard_WIP,
  ].includes(state as DashboardClientStates);

export type DashboardClientData_Authorised = {};

export enum DashboardClientMessages_Authorised {
  FetchDashboard = "fetch-dashboard",
  AuthLogout = "auth-logout",
}

export const isDashboardClientState_FetchDashboard_WIP = (state: string) =>
  state === DashboardClientStates.FetchDashboard_WIP;

//-----------------------------------------------------------------------------------------------------------
// State: ready
//-----------------------------------------------------------------------------------------------------------

export const isDashboardClientState_Ready = (state: string) =>
  [DashboardClientStates.Ready].includes(state as DashboardClientStates);

export type DashboardClientData_Ready = {
  users: Array<UserSummary>;
};

export enum DashboardClientMessages_Ready {
  UnloadDashboard = "unload-dashboard",
}

//-----------------------------------------------------------------------------------------------------------
