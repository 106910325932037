//-----------------------------------------------------------------------------------------------------------
// File: src/App.tsx
//-----------------------------------------------------------------------------------------------------------

import React from "react";
import LoadingWrapper from "./components/loader/LoadingWrapper";
import Login from "./screens/login/Login";
import Home from "./screens/home/Home";
import "./App.css";
import { useEffect, useState } from "react";
import {
  reset,
  useIsAuthClientState_Authorised,
  useIsAuthClientState_Expired,
  useIsAuthClientState_UnAuthorised,
} from "./actors/auth-client";
import { afterDelayOf } from "./utils/exec";
import ChangePassword from "./screens/change-password/ChangePassword";
import AuthHandler from "./components/AuthHandler";
import { startActors, stopActors } from "./actors";
import Users from "./screens/users/Users";
import Projects from "./screens/projects/Projects";
import Kanban from "./screens/kanban/Kanban";
import Loader from "./components/loader/Loader";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

//-----------------------------------------------------------------------------------------------------------

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const isUnAuthorised = useIsAuthClientState_UnAuthorised();
  const isAuthorised = useIsAuthClientState_Authorised();
  const isExpired = useIsAuthClientState_Expired();

  useEffect(() => {
    const init = async () => {
      await startActors();
      afterDelayOf(1, () => setIsLoading(false));
    };
    init();
    return () => {
      stopActors();
    };
  }, []);

  const handleBackToLogin = () => reset();

  return (
    <Router>
      <LoadingWrapper isLoading={isLoading}>
        <AuthHandler />
        {isUnAuthorised && <Login />}
        {isAuthorised && (
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/users" element={<Users />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/kanban" element={<Kanban />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Routes>
          </div>
        )}
        {isExpired && (
          <div className="App">
            Session has expired{" "}
            <button onClick={handleBackToLogin}>Back to Login Page</button>
          </div>
        )}
      </LoadingWrapper>
    </Router>
  );
};

//-----------------------------------------------------------------------------------------------------------

export default App;

//-----------------------------------------------------------------------------------------------------------
