//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/auth-client/actor.ts
//-----------------------------------------------------------------------------------------------------------

import {
  Actor,
  ActorMeta,
  Message,
  MessageResult,
  getActor,
  sendMessage,
  listenTo,
  stopListeningTo,
} from "../../utils/modulo-plus";
import {
  AuthClientData_UnAuthorised,
  AuthClientData_Authorised,
  AuthClientData_Expired,
  AuthClientMessages_UnAuthorised,
  AuthClientMessages_Authorised,
  AuthClientMessages_Expired,
  AuthClientName,
  AuthClientStates,
  isAuthClientState_Authorised,
} from "./def";
import {
  onLogin,
  onLoginFailure,
  onLoginSuccess,
} from "./states/un-authorised";
import {
  onLogout,
  onLogoutFailure,
  onLogoutSuccess,
  onExpired,
  onChangePassword,
  onChangePasswordFailure,
  onChangePasswordSuccess,
} from "./states/authorised";
import { onReset } from "./states/expired";

//-----------------------------------------------------------------------------------------------------------

export const createAuthClient = (
  actor?: Actor,
  config: Record<string, any> = {},
): Actor & ActorMeta =>
  actor
    ? { ...actor, messageHandler, config }
    : {
        name: AuthClientName,
        state: AuthClientStates.UnAuthorised,
        data: {} as AuthClientData_UnAuthorised,
        messageHandler,
        config,
      };

//-----------------------------------------------------------------------------------------------------------

export const destroyAuthClient = () => {};

//-----------------------------------------------------------------------------------------------------------

const messageHandler = async (
  message: Message,
  actor: Actor,
): Promise<Actor> => {
  const { name, payload, from, to } = message;

  if (!from || !to) {
    console.error(`Message missing 'from' or 'to' fields:`, message);
    return actor;
  }

  switch (actor.state as AuthClientStates) {
    case AuthClientStates.UnAuthorised:
      switch (name as AuthClientMessages_UnAuthorised) {
        case AuthClientMessages_UnAuthorised.Login:
          await onLogin(payload.loginId, payload.password, message.id!);
          return { ...actor, state: AuthClientStates.Login_WIP };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    case AuthClientStates.Login_WIP:
      switch (name) {
        case `${AuthClientMessages_UnAuthorised.Login}-${MessageResult.Success}`:
          return {
            ...actor,
            state: AuthClientStates.Authorised,
            data: await onLoginSuccess(payload, message.id!),
          };

        case `${AuthClientMessages_UnAuthorised.Login}-${MessageResult.Failure}`:
          return {
            ...actor,
            state: AuthClientStates.UnAuthorised,
            error: await onLoginFailure(payload, message.id!),
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    case AuthClientStates.Authorised:
      switch (name as AuthClientMessages_Authorised) {
        case AuthClientMessages_Authorised.Logout:
          await onLogout(message.id!);
          return { ...actor, state: AuthClientStates.Logout_WIP };

        case AuthClientMessages_Authorised.Expired:
          let dataExpired = await onExpired(message.id!);
          return {
            ...actor,
            data: dataExpired,
            state: AuthClientStates.Expired,
          };

        case AuthClientMessages_Authorised.ChangePassword:
          await onChangePassword(
            payload.currentPassword,
            payload.newPassword,
            message.id!,
          );
          return { ...actor, state: AuthClientStates.ChangePassword_WIP };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    case AuthClientStates.Logout_WIP:
      switch (name) {
        case `${AuthClientMessages_Authorised.Logout}-${MessageResult.Success}`:
          return {
            ...actor,
            state: AuthClientStates.UnAuthorised,
            data: await onLogoutSuccess(payload, message.id!),
          };

        case `${AuthClientMessages_Authorised.Logout}-${MessageResult.Failure}`:
          return {
            ...actor,
            state: AuthClientStates.Authorised,
            error: await onLogoutFailure(payload, message.id!),
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    case AuthClientStates.ChangePassword_WIP:
      switch (name) {
        case `${AuthClientMessages_Authorised.ChangePassword}-${MessageResult.Success}`:
          await onChangePasswordSuccess(payload, message.id!);
          return {
            ...actor,
            state: AuthClientStates.Authorised,
          };

        case `${AuthClientMessages_Authorised.ChangePassword}-${MessageResult.Failure}`:
          return {
            ...actor,
            state: AuthClientStates.Authorised,
            error: await onChangePasswordFailure(payload, message.id!),
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    case AuthClientStates.Expired:
      switch (name as AuthClientMessages_Expired) {
        case AuthClientMessages_Expired.Reset:
          let dataReset = await onReset(message.id!);
          return {
            ...actor,
            data: dataReset,
            state: AuthClientStates.UnAuthorised,
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    default:
      throw new Error(`Unhandled state: ${actor.state}`);
  }
};

//-----------------------------------------------------------------------------------------------------------
// Syntax sugar for messages
//-----------------------------------------------------------------------------------------------------------

// State: un-authorised

export const login = (loginId: string, password: string) => {
  const actor = getActor(AuthClientName)!;
  sendMessage({
    name: AuthClientMessages_UnAuthorised.Login,
    payload: {
      loginId,
      password,
    },
    to: actor.name,
    confidential: true,
  });
};

// State: authorised

export const logout = () => {
  const actor = getActor(AuthClientName)!;
  sendMessage({
    name: AuthClientMessages_Authorised.Logout,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

export const expired = () => {
  const actor = getActor(AuthClientName)!;
  sendMessage({
    name: AuthClientMessages_Authorised.Expired,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

export const changePassword = (
  currentPassword: string,
  newPassword: string,
) => {
  const actor = getActor(AuthClientName)!;
  sendMessage({
    name: AuthClientMessages_Authorised.ChangePassword,
    payload: {
      currentPassword,
      newPassword,
    },
    to: actor.name,
    confidential: false,
  });
};

// State: expired

export const reset = () => {
  const actor = getActor(AuthClientName)!;
  sendMessage({
    name: AuthClientMessages_Expired.Reset,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

//-----------------------------------------------------------------------------------------------------------
// Misc
//-----------------------------------------------------------------------------------------------------------

export const withSessionToken = (message: Message) => {
  const authActor = getActor(AuthClientName);
  if (authActor && isAuthClientState_Authorised(authActor.state!)) {
    return { ...message, token: authActor.data.token };
  } else return message;
};

//-----------------------------------------------------------------------------------------------------------
