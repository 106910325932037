// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: /src/components/header/menu-button/MenuButton.css */

.header-button {
  margin-top: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/menu-button/MenuButton.css"],"names":[],"mappings":"AAAA,4DAA4D;;AAE5D;EACE,0BAA0B;AAC5B","sourcesContent":["/* File: /src/components/header/menu-button/MenuButton.css */\n\n.header-button {\n  margin-top: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
