//-----------------------------------------------------------------------------------------------------------
// File: /src/components/header/home-button/HomeButton.tsx
//-----------------------------------------------------------------------------------------------------------

import { useNavigate } from "react-router-dom";
import iRealitiesLogo from "../../../assets/iRealitiesLogo.png";
import "./HomeButton.css";

//-----------------------------------------------------------------------------------------------------------

export function HomeButton({ isWideScreen }: { isWideScreen?: boolean }) {
  const navigate = useNavigate();
  return (
    <a
      href="/"
      className={`home-logo-a${isWideScreen ? "-wide-screen" : ""}`}
      onClick={() => navigate("/")}
    >
      <img src={iRealitiesLogo} alt="i.Realities Logo" className="home-logo" />
    </a>
  );
}

//-----------------------------------------------------------------------------------------------------------
