//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/dashboard-client/actor.ts
//-----------------------------------------------------------------------------------------------------------

import {
  Actor,
  ActorMeta,
  Message,
  MessageResult,
  getActor,
  sendMessage,
  listenTo,
  stopListeningTo,
} from "../../utils/modulo-plus";
import {
  DashboardClientData_UnAuthorised,
  DashboardClientData_Authorised,
  DashboardClientData_Ready,
  DashboardClientMessages_UnAuthorised,
  DashboardClientMessages_Authorised,
  DashboardClientMessages_Ready,
  DashboardClientName,
  DashboardClientStates,
  isDashboardClientState_UnAuthorised,
} from "./def";
import { onAuthLogin } from "./states/un-authorised";
import {
  onFetchDashboard,
  onFetchDashboardFailure,
  onFetchDashboardSuccess,
  onAuthLogout,
} from "./states/authorised";
import { onUnloadDashboard } from "./states/ready";
import { AuthClientName, isAuthClientState_Authorised } from "../auth-client";

//-----------------------------------------------------------------------------------------------------------

export const createDashboardClient = (
  actor?: Actor,
  config: Record<string, any> = {},
): Actor & ActorMeta => {
  listenTo(AuthClientName, authClientListener);
  return actor
    ? { ...actor, messageHandler, config }
    : {
        name: DashboardClientName,
        state: DashboardClientStates.UnAuthorised,
        data: {} as DashboardClientData_UnAuthorised,
        messageHandler,
        config,
      };
};

//-----------------------------------------------------------------------------------------------------------

const authClientListener = (actor: Actor) => {
  const me = getActor(DashboardClientName);
  if (!me) return;
  if (isAuthClientState_Authorised(actor.state)) {
    if (isDashboardClientState_UnAuthorised(me.state))
      sendMessage({
        name: DashboardClientMessages_UnAuthorised.AuthLogin,
        from: DashboardClientName,
        to: DashboardClientName,
      });
  } else {
    if (!isDashboardClientState_UnAuthorised(me.state))
      sendMessage({
        name: DashboardClientMessages_Authorised.AuthLogout,
        from: DashboardClientName,
        to: DashboardClientName,
      });
  }
};

//-----------------------------------------------------------------------------------------------------------

export const destroyDashboardClient = () => {
  stopListeningTo(DashboardClientName, authClientListener);
};

//-----------------------------------------------------------------------------------------------------------

const messageHandler = async (
  message: Message,
  actor: Actor,
): Promise<Actor> => {
  const { name, payload, from, to } = message;

  if (!from || !to) {
    console.error(`Message missing 'from' or 'to' fields:`, message);
    return actor;
  }

  // Handle login & logout from auth controller
  if (name === DashboardClientMessages_UnAuthorised.AuthLogin) {
    if (isDashboardClientState_UnAuthorised(actor.state)) {
      let dataAuthLogin = await onAuthLogin(message.id!);
      return {
        ...actor,
        data: dataAuthLogin,
        state: DashboardClientStates.Authorised,
      };
    } else return actor;
  } else if (name === DashboardClientMessages_Authorised.AuthLogout) {
    if (!isDashboardClientState_UnAuthorised(actor.state)) {
      let dataAuthLogout = await onAuthLogout(message.id!);
      return {
        ...actor,
        data: dataAuthLogout,
        state: DashboardClientStates.UnAuthorised,
      };
    } else return actor;
  }

  // Handle other messages
  switch (actor.state as DashboardClientStates) {
    case DashboardClientStates.Authorised:
      switch (name as DashboardClientMessages_Authorised) {
        case DashboardClientMessages_Authorised.FetchDashboard:
          await onFetchDashboard(message.id!);
          return { ...actor, state: DashboardClientStates.FetchDashboard_WIP };

        case DashboardClientMessages_Authorised.AuthLogout:
          let dataAuthLogout = await onAuthLogout(message.id!);
          return {
            ...actor,
            data: dataAuthLogout,
            state: DashboardClientStates.UnAuthorised,
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    case DashboardClientStates.FetchDashboard_WIP:
      switch (name) {
        case `${DashboardClientMessages_Authorised.FetchDashboard}-${MessageResult.Success}`:
          return {
            ...actor,
            state: DashboardClientStates.Ready,
            data: await onFetchDashboardSuccess(payload, message.id!),
          };

        case `${DashboardClientMessages_Authorised.FetchDashboard}-${MessageResult.Failure}`:
          return {
            ...actor,
            state: DashboardClientStates.Authorised,
            error: await onFetchDashboardFailure(payload, message.id!),
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    case DashboardClientStates.Ready:
      switch (name as DashboardClientMessages_Ready) {
        case DashboardClientMessages_Ready.UnloadDashboard:
          let dataUnloadDashboard = await onUnloadDashboard(message.id!);
          return {
            ...actor,
            data: dataUnloadDashboard,
            state: DashboardClientStates.Authorised,
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    default:
      throw new Error(`Unhandled state: ${actor.state}`);
  }
};

//-----------------------------------------------------------------------------------------------------------
// Syntax sugar for messages
//-----------------------------------------------------------------------------------------------------------

// State: un-authorised

export const authLogin = () => {
  const actor = getActor(DashboardClientName)!;
  sendMessage({
    name: DashboardClientMessages_UnAuthorised.AuthLogin,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

// State: authorised

export const fetchDashboard = () => {
  const actor = getActor(DashboardClientName)!;
  sendMessage({
    name: DashboardClientMessages_Authorised.FetchDashboard,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

export const authLogout = () => {
  const actor = getActor(DashboardClientName)!;
  sendMessage({
    name: DashboardClientMessages_Authorised.AuthLogout,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

// State: ready

export const unloadDashboard = () => {
  const actor = getActor(DashboardClientName)!;
  sendMessage({
    name: DashboardClientMessages_Ready.UnloadDashboard,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

//-----------------------------------------------------------------------------------------------------------
// Misc
//-----------------------------------------------------------------------------------------------------------

//-----------------------------------------------------------------------------------------------------------
