//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/auth-client/service-stub.ts
//-----------------------------------------------------------------------------------------------------------

import {
  Actor,
  ActorMeta,
  MessageResult,
  Message,
  sendMessage,
} from "../../utils/modulo-plus";
import { afterDelayOf } from "../../utils/exec";
import {
  AuthClientMessages_UnAuthorised,
  AuthClientMessages_Authorised,
  AuthClientMessages_Expired,
  AuthClientName,
  AuthServiceName,
} from "./def";

//-----------------------------------------------------------------------------------------------------------
// Constructor
//-----------------------------------------------------------------------------------------------------------

export const createAuthServiceStub = (
  actor?: Actor,
  config: Record<string, any> = {},
): Actor & ActorMeta =>
  actor
    ? { ...actor, messageHandler, config }
    : {
        name: AuthServiceName,
        state: "ready",
        data: {},
        messageHandler,
        config,
      };

//-----------------------------------------------------------------------------------------------------------
// Message Handler
//-----------------------------------------------------------------------------------------------------------

const messageHandler = async (
  message: Message,
  actor: Actor,
): Promise<Actor> => {
  afterDelayOf(1, () => {
    const { name: messageName, payload, from, to } = message;

    if (!from || !to) {
      console.error(`Message missing 'from' or 'to' fields:`, message);
      return;
    }

    const replyBase = {
      from: to,
      to: from,
      confidential: message.confidential,
    };

    switch (messageName) {
      case AuthClientMessages_UnAuthorised.Login:
        {
          const { loginId, password } = payload;
          const throwError = false;
          if (!throwError)
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Success}`,
              payload: {
                id: "user-1",
                fullName: "Manoj Kumar A",
                token: "session-123",
              },
            });
          else
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Failure}`,
              payload: "Invalid login credentials",
            });
        }
        break;

      case AuthClientMessages_Authorised.Logout:
        {
          const {} = payload;
          const throwError = false;
          if (!throwError)
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Success}`,
              payload: {},
            });
          else
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Failure}`,
              payload: "",
            });
        }
        break;

      case AuthClientMessages_Authorised.ChangePassword:
        {
          const { currentPassword, newPassword } = payload;
          const throwError = false;
          if (!throwError)
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Success}`,
              payload: {},
            });
          else
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Failure}`,
              payload: "",
            });
        }
        break;

      default:
        sendMessage({
          ...replyBase,
          name: `${messageName}-${MessageResult.Failure}`,
          payload: "Invalid message",
        });
    }
  });

  return actor;
};

//-----------------------------------------------------------------------------------------------------------
