//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/auth-client/hooks.ts
//-----------------------------------------------------------------------------------------------------------

import { useEffect, useRef, useState } from "react";
import {
  Actor,
  getActor,
  listenTo,
  stopListeningTo,
} from "../../utils/modulo-plus";
import {
  AuthClientName,
  AuthClientData_UnAuthorised,
  AuthClientData_Authorised,
  AuthClientData_Expired,
  isAuthClientState_UnAuthorised,
  isAuthClientState_Login_WIP,
  isAuthClientState_Authorised,
  isAuthClientState_Logout_WIP,
  isAuthClientState_ChangePassword_WIP,
  isAuthClientState_Expired,
} from "./def";

//-----------------------------------------------------------------------------------------------------------
// Actor
//-----------------------------------------------------------------------------------------------------------

export const useAuthClientState = () => {
  const [state, setState] = useState(() => getActor(AuthClientName)?.state);
  const previousStateRef = useRef(state);

  useEffect(() => {
    const updateState = (actor: Actor) => {
      previousStateRef.current = state;
      setState(actor.state);
    };
    listenTo(AuthClientName, updateState);
    return () => stopListeningTo(AuthClientName, updateState);
  }, [state]);

  return { state, previousState: previousStateRef.current };
};

export const useAuthClientData = () => {
  const [data, setData] = useState(() => getActor(AuthClientName)?.data);
  useEffect(() => {
    const updateData = (actor: Actor) => setData(actor.data);
    listenTo(AuthClientName, updateData);
    return () => stopListeningTo(AuthClientName, updateData);
  }, []);
  return data;
};

export const useAuthClientError = () => {
  const [error, setError] = useState(() => getActor(AuthClientName)?.error);
  useEffect(() => {
    const updateData = (actor: Actor) => setError(actor.error);
    listenTo(AuthClientName, updateData);
    return () => stopListeningTo(AuthClientName, updateData);
  }, []);
  return error;
};

//-----------------------------------------------------------------------------------------------------------
// State: UnAuthorised
//-----------------------------------------------------------------------------------------------------------

export const useIsAuthClientState_UnAuthorised = () => {
  const authClientState = useAuthClientState();
  return isAuthClientState_UnAuthorised(authClientState.state!);
};

export const useIsAuthClientState_Login_WIP = () => {
  const authClientState = useAuthClientState();
  return isAuthClientState_Login_WIP(authClientState.state!);
};

export const useAuthClientData_UnAuthorised = () => {
  const [authData, setAuthData] = useState<
    AuthClientData_UnAuthorised | undefined
  >(() => {
    const actor = getActor(AuthClientName);
    return actor && isAuthClientState_UnAuthorised(actor!.state)
      ? (actor.data as AuthClientData_UnAuthorised)
      : undefined;
  });
  useEffect(() => {
    const handleActorChange = (actor: Actor) => {
      if (isAuthClientState_UnAuthorised(actor.state)) {
        setAuthData(actor.data as AuthClientData_UnAuthorised);
      } else {
        setAuthData(undefined);
      }
    };

    // Subscribe to changes in the AuthClient
    listenTo(AuthClientName, handleActorChange);
    // Cleanup subscription on unmount
    return () => stopListeningTo(AuthClientName, handleActorChange);
  }, []);
  return authData;
};

//-----------------------------------------------------------------------------------------------------------
// State: Authorised
//-----------------------------------------------------------------------------------------------------------

export const useIsAuthClientState_Authorised = () => {
  const authClientState = useAuthClientState();
  return isAuthClientState_Authorised(authClientState.state!);
};

export const useIsAuthClientState_Logout_WIP = () => {
  const authClientState = useAuthClientState();
  return isAuthClientState_Logout_WIP(authClientState.state!);
};

export const useIsAuthClientState_ChangePassword_WIP = () => {
  const authClientState = useAuthClientState();
  return isAuthClientState_ChangePassword_WIP(authClientState.state!);
};

export const useAuthClientData_Authorised = () => {
  const [authData, setAuthData] = useState<
    AuthClientData_Authorised | undefined
  >(() => {
    const actor = getActor(AuthClientName);
    return actor && isAuthClientState_Authorised(actor!.state)
      ? (actor.data as AuthClientData_Authorised)
      : undefined;
  });
  useEffect(() => {
    const handleActorChange = (actor: Actor) => {
      if (isAuthClientState_Authorised(actor.state)) {
        setAuthData(actor.data as AuthClientData_Authorised);
      } else {
        setAuthData(undefined);
      }
    };

    // Subscribe to changes in the AuthClient
    listenTo(AuthClientName, handleActorChange);
    // Cleanup subscription on unmount
    return () => stopListeningTo(AuthClientName, handleActorChange);
  }, []);
  return authData;
};

//-----------------------------------------------------------------------------------------------------------
// State: Expired
//-----------------------------------------------------------------------------------------------------------

export const useIsAuthClientState_Expired = () => {
  const authClientState = useAuthClientState();
  return isAuthClientState_Expired(authClientState.state!);
};

export const useAuthClientData_Expired = () => {
  const [authData, setAuthData] = useState<AuthClientData_Expired | undefined>(
    () => {
      const actor = getActor(AuthClientName);
      return actor && isAuthClientState_Expired(actor!.state)
        ? (actor.data as AuthClientData_Expired)
        : undefined;
    },
  );
  useEffect(() => {
    const handleActorChange = (actor: Actor) => {
      if (isAuthClientState_Expired(actor.state)) {
        setAuthData(actor.data as AuthClientData_Expired);
      } else {
        setAuthData(undefined);
      }
    };

    // Subscribe to changes in the AuthClient
    listenTo(AuthClientName, handleActorChange);
    // Cleanup subscription on unmount
    return () => stopListeningTo(AuthClientName, handleActorChange);
  }, []);
  return authData;
};

//-----------------------------------------------------------------------------------------------------------
