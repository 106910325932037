//-----------------------------------------------------------------------------------------------------------
// File: /src/components/button/MyButton.tsx
//-----------------------------------------------------------------------------------------------------------

import "./MyButton.css";

//-----------------------------------------------------------------------------------------------------------

interface MyButtonProps {
  name: string;
  isFullWidth?: boolean;
  isUppercase?: boolean;
  onClick: () => void;
  customClasses?: string;
  isMain?: boolean;
  isSmall?: boolean;
}

//-----------------------------------------------------------------------------------------------------------

function MyButton({
  name,
  isFullWidth,
  isUppercase = true,
  onClick: clickHandler,
  customClasses,
  isMain,
  isSmall,
}: MyButtonProps) {
  return (
    <button
      className={`${customClasses ? customClasses : "button"} ${isMain ? "is-primary" : ""} ${isFullWidth ? "is-fullwidth" : ""} ${isUppercase ? "is-uppercase" : ""} ${isSmall ? "is-small" : ""}`}
      onClick={clickHandler}
    >
      {name}
    </button>
  );
}

//-----------------------------------------------------------------------------------------------------------

export default MyButton;

//-----------------------------------------------------------------------------------------------------------
