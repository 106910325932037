//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/user-client/def.ts
//-----------------------------------------------------------------------------------------------------------

// Enums
export enum UserPrivilege {
  SuperAdmin = "super-admin",
  Admin = "admin",
  Standard = "standard",
  Restricted = "restricted",
}

export enum DepartmentState {
  Active = "active",
  Inactive = "inactive",
}

export enum UserState {
  Active = "active",
  Inactive = "inactive",
}

export enum UserType {
  Employee = "employee",
  Consultant = "consultant",
  Intern = "intern",
  Vendor = "vendor",
  Client = "client",
  Guest = "guest",
}

export enum SessionState {
  Active = "active",
  Closed = "closed",
  TimedOut = "timed-out",
}

//-----------------------------------------------------------------------------------------------------------

// Types
export type Department = {
  id: string;
  name: string;
  state: DepartmentState;
};

export type User = {
  id: string;
  loginId: string;
  state: UserState;
  fullName: string;
  password: string;
  privilege: UserPrivilege;
  department?: Department | { id: string; name: string };
  designation?: string;
  type: UserType;
  address?: string;
  emails: string;
  phones: string;
};

export type Session = {
  id: string;
  user: User | { id: string; name: string };
  startedAt: Date;
  validTill?: Date;
  lastActivityAt: Date;
  closedAt?: Date;
  state: SessionState;
};

//-----------------------------------------------------------------------------------------------------------
// Actor
//-----------------------------------------------------------------------------------------------------------

export const UserClientName = "user-client";
export const UserServiceName = "user-service";

export enum UserClientStates {
  // Default state: un-authorised

  UnAuthorised = "un-authorised",

  Authorised = "authorised",
  LoadUsers_WIP = "load-users-wip",

  Ready = "ready",
}

//-----------------------------------------------------------------------------------------------------------
// State: un-authorised
//-----------------------------------------------------------------------------------------------------------

export const isUserClientState_UnAuthorised = (state: string) =>
  [UserClientStates.UnAuthorised].includes(state as UserClientStates);

export type UserClientData_UnAuthorised = {};

export enum UserClientMessages_UnAuthorised {
  AuthLogin = "auth-login",
}

//-----------------------------------------------------------------------------------------------------------
// State: authorised
//-----------------------------------------------------------------------------------------------------------

export const isUserClientState_Authorised = (state: string) =>
  [UserClientStates.Authorised, UserClientStates.LoadUsers_WIP].includes(
    state as UserClientStates,
  );

export type UserClientData_Authorised = {};

export enum UserClientMessages_Authorised {
  LoadUsers = "load-users",
  AuthLogout = "auth-logout",
}

export const isUserClientState_LoadUsers_WIP = (state: string) =>
  state === UserClientStates.LoadUsers_WIP;

//-----------------------------------------------------------------------------------------------------------
// State: ready
//-----------------------------------------------------------------------------------------------------------

export const isUserClientState_Ready = (state: string) =>
  [UserClientStates.Ready].includes(state as UserClientStates);

export type UserClientData_Ready = {
  users: Array<User>;
};

export enum UserClientMessages_Ready {
  UnloadUsers = "unload-users",
}

//-----------------------------------------------------------------------------------------------------------
