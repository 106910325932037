//-----------------------------------------------------------------------------------------------------------
// File: /src/components/error-message/ErrorMessage.tsx
//-----------------------------------------------------------------------------------------------------------

import "./ErrorMessage.css";

//-----------------------------------------------------------------------------------------------------------

interface ErrorMessageProps {
  text: string;
  isFullWidth?: boolean;
  isUppercase?: boolean;
}

//-----------------------------------------------------------------------------------------------------------

function ErrorMessage({ text, isFullWidth, isUppercase }: ErrorMessageProps) {
  return (
    <span
      className={`has-text-danger help ${isFullWidth ? "is-fullwidth" : ""} ${isUppercase ? "is-uppercase" : ""}`}
    >
      {text}
    </span>
  );
}
//-----------------------------------------------------------------------------------------------------------

export default ErrorMessage;

//-----------------------------------------------------------------------------------------------------------
