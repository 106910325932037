//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/auth-client/def.ts
//-----------------------------------------------------------------------------------------------------------

// Enums

//-----------------------------------------------------------------------------------------------------------

// Types

//-----------------------------------------------------------------------------------------------------------
// Actor
//-----------------------------------------------------------------------------------------------------------

export const AuthClientName = "auth-client";
export const AuthServiceName = "auth-service";

export enum AuthClientStates {
  // Default state: un-authorised

  UnAuthorised = "un-authorised",
  Login_WIP = "login-wip",

  Authorised = "authorised",
  Logout_WIP = "logout-wip",
  ChangePassword_WIP = "change-password-wip",

  Expired = "expired",
}

//-----------------------------------------------------------------------------------------------------------
// State: un-authorised
//-----------------------------------------------------------------------------------------------------------

export const isAuthClientState_UnAuthorised = (state: string) =>
  [AuthClientStates.UnAuthorised, AuthClientStates.Login_WIP].includes(
    state as AuthClientStates,
  );

export type AuthClientData_UnAuthorised = {};

export enum AuthClientMessages_UnAuthorised {
  Login = "login",
}

export const isAuthClientState_Login_WIP = (state: string) =>
  state === AuthClientStates.Login_WIP;

//-----------------------------------------------------------------------------------------------------------
// State: authorised
//-----------------------------------------------------------------------------------------------------------

export const isAuthClientState_Authorised = (state: string) =>
  [
    AuthClientStates.Authorised,
    AuthClientStates.Logout_WIP,
    AuthClientStates.ChangePassword_WIP,
  ].includes(state as AuthClientStates);

export type AuthClientData_Authorised = {
  id: string;
  fullName: string;
  token: string;
};

export enum AuthClientMessages_Authorised {
  Logout = "logout",
  Expired = "expired",
  ChangePassword = "change-password",
}

export const isAuthClientState_Logout_WIP = (state: string) =>
  state === AuthClientStates.Logout_WIP;

export const isAuthClientState_ChangePassword_WIP = (state: string) =>
  state === AuthClientStates.ChangePassword_WIP;

//-----------------------------------------------------------------------------------------------------------
// State: expired
//-----------------------------------------------------------------------------------------------------------

export const isAuthClientState_Expired = (state: string) =>
  [AuthClientStates.Expired].includes(state as AuthClientStates);

export type AuthClientData_Expired = {};

export enum AuthClientMessages_Expired {
  Reset = "reset",
}

//-----------------------------------------------------------------------------------------------------------
