//-----------------------------------------------------------------------------------------------------------
// File: /src/components/loader/LoadingWrapper.tsx
//-----------------------------------------------------------------------------------------------------------

import React from "react";
import Loader from "./Loader";

//-----------------------------------------------------------------------------------------------------------

interface LoadingWrapperProps {
  isLoading: boolean;
  children: React.ReactNode;
}

//-----------------------------------------------------------------------------------------------------------

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({
  isLoading,
  children,
}) => {
  if (isLoading) {
    return <Loader text="Loading, please wait..." isFullWidth={true} />;
  }
  return <>{children}</>;
};

//-----------------------------------------------------------------------------------------------------------

export default LoadingWrapper;

//-----------------------------------------------------------------------------------------------------------
