//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/auth-client/states/un-authorised.ts
//-----------------------------------------------------------------------------------------------------------

import { sendMessage, Message } from "../../../utils/modulo-plus";
import {
  AuthClientData_UnAuthorised,
  AuthClientData_Authorised,
  AuthClientData_Expired,
  AuthClientMessages_UnAuthorised,
  AuthClientMessages_Authorised,
  AuthClientMessages_Expired,
  AuthClientName,
  AuthServiceName,
} from "../def";
import { withSessionToken } from "../../auth-client";

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: login
//-----------------------------------------------------------------------------------------------------------

export async function onLogin(
  loginId: string,
  password: string,
  messageId: string,
) {
  // TODO: Add validations here. If they fail, throw an error.

  sendMessage({
    id: messageId,
    name: AuthClientMessages_UnAuthorised.Login,
    payload: {
      loginId,
      password,
    },
    from: AuthClientName,
    to: AuthServiceName,
    confidential: true,
  });
}

export async function onLoginSuccess(
  data: AuthClientData_Authorised,
  messageId: string,
): Promise<AuthClientData_Authorised> {
  // TODO: Add any post-processing of data if required.
  return data;
}

export async function onLoginFailure(
  error: string,
  messageId: string,
): Promise<string> {
  // TODO: Add any post-processing of the error if required.
  return error;
}

//-----------------------------------------------------------------------------------------------------------
