//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/user-client/actor.ts
//-----------------------------------------------------------------------------------------------------------

import {
  Actor,
  ActorMeta,
  Message,
  MessageResult,
  getActor,
  sendMessage,
  listenTo,
  stopListeningTo,
} from "../../utils/modulo-plus";
import {
  UserClientData_UnAuthorised,
  UserClientData_Authorised,
  UserClientData_Ready,
  UserClientMessages_UnAuthorised,
  UserClientMessages_Authorised,
  UserClientMessages_Ready,
  UserClientName,
  UserClientStates,
  isUserClientState_UnAuthorised,
} from "./def";
import { onAuthLogin } from "./states/un-authorised";
import {
  onLoadUsers,
  onLoadUsersFailure,
  onLoadUsersSuccess,
  onAuthLogout,
} from "./states/authorised";
import { onUnloadUsers } from "./states/ready";
import { AuthClientName, isAuthClientState_Authorised } from "../auth-client";

//-----------------------------------------------------------------------------------------------------------

export const createUserClient = (
  actor?: Actor,
  config: Record<string, any> = {},
): Actor & ActorMeta => {
  listenTo(AuthClientName, authClientListener);
  return actor
    ? { ...actor, messageHandler, config }
    : {
        name: UserClientName,
        state: UserClientStates.UnAuthorised,
        data: {} as UserClientData_UnAuthorised,
        messageHandler,
        config,
      };
};

//-----------------------------------------------------------------------------------------------------------

const authClientListener = (actor: Actor) => {
  const me = getActor(UserClientName);
  if (!me) return;
  if (isAuthClientState_Authorised(actor.state)) {
    if (isUserClientState_UnAuthorised(me.state))
      sendMessage({
        name: UserClientMessages_UnAuthorised.AuthLogin,
        from: UserClientName,
        to: UserClientName,
      });
  } else {
    if (!isUserClientState_UnAuthorised(me.state))
      sendMessage({
        name: UserClientMessages_Authorised.AuthLogout,
        from: UserClientName,
        to: UserClientName,
      });
  }
};

//-----------------------------------------------------------------------------------------------------------

export const destroyUserClient = () => {
  stopListeningTo(UserClientName, authClientListener);
};

//-----------------------------------------------------------------------------------------------------------

const messageHandler = async (
  message: Message,
  actor: Actor,
): Promise<Actor> => {
  const { name, payload, from, to } = message;

  if (!from || !to) {
    console.error(`Message missing 'from' or 'to' fields:`, message);
    return actor;
  }

  // Handle login & logout from auth controller
  if (name === UserClientMessages_UnAuthorised.AuthLogin) {
    if (isUserClientState_UnAuthorised(actor.state)) {
      let dataAuthLogin = await onAuthLogin(message.id!);
      return {
        ...actor,
        data: dataAuthLogin,
        state: UserClientStates.Authorised,
      };
    } else return actor;
  } else if (name === UserClientMessages_Authorised.AuthLogout) {
    if (!isUserClientState_UnAuthorised(actor.state)) {
      let dataAuthLogout = await onAuthLogout(message.id!);
      return {
        ...actor,
        data: dataAuthLogout,
        state: UserClientStates.UnAuthorised,
      };
    } else return actor;
  }

  // Handle other messages
  switch (actor.state as UserClientStates) {
    case UserClientStates.Authorised:
      switch (name as UserClientMessages_Authorised) {
        case UserClientMessages_Authorised.LoadUsers:
          await onLoadUsers(message.id!);
          return { ...actor, state: UserClientStates.LoadUsers_WIP };

        case UserClientMessages_Authorised.AuthLogout:
          let dataAuthLogout = await onAuthLogout(message.id!);
          return {
            ...actor,
            data: dataAuthLogout,
            state: UserClientStates.UnAuthorised,
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    case UserClientStates.LoadUsers_WIP:
      switch (name) {
        case `${UserClientMessages_Authorised.LoadUsers}-${MessageResult.Success}`:
          return {
            ...actor,
            state: UserClientStates.Ready,
            data: await onLoadUsersSuccess(payload, message.id!),
          };

        case `${UserClientMessages_Authorised.LoadUsers}-${MessageResult.Failure}`:
          return {
            ...actor,
            state: UserClientStates.Authorised,
            error: await onLoadUsersFailure(payload, message.id!),
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    case UserClientStates.Ready:
      switch (name as UserClientMessages_Ready) {
        case UserClientMessages_Ready.UnloadUsers:
          let dataUnloadUsers = await onUnloadUsers(message.id!);
          return {
            ...actor,
            data: dataUnloadUsers,
            state: UserClientStates.Authorised,
          };

        default:
          throw new Error(`Invalid message: ${actor.name}/${name}`);
      }

    default:
      throw new Error(`Unhandled state: ${actor.state}`);
  }
};

//-----------------------------------------------------------------------------------------------------------
// Syntax sugar for messages
//-----------------------------------------------------------------------------------------------------------

// State: un-authorised

export const authLogin = () => {
  const actor = getActor(UserClientName)!;
  sendMessage({
    name: UserClientMessages_UnAuthorised.AuthLogin,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

// State: authorised

export const loadUsers = () => {
  const actor = getActor(UserClientName)!;
  sendMessage({
    name: UserClientMessages_Authorised.LoadUsers,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

export const authLogout = () => {
  const actor = getActor(UserClientName)!;
  sendMessage({
    name: UserClientMessages_Authorised.AuthLogout,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

// State: ready

export const unloadUsers = () => {
  const actor = getActor(UserClientName)!;
  sendMessage({
    name: UserClientMessages_Ready.UnloadUsers,
    payload: {},
    to: actor.name,
    confidential: false,
  });
};

//-----------------------------------------------------------------------------------------------------------
// Misc
//-----------------------------------------------------------------------------------------------------------

//-----------------------------------------------------------------------------------------------------------
