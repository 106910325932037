//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/dashboard-client/states/ready.ts
//-----------------------------------------------------------------------------------------------------------

import { sendMessage, Message } from "../../../utils/modulo-plus";
import {
  DashboardClientData_UnAuthorised,
  DashboardClientData_Authorised,
  DashboardClientData_Ready,
  DashboardClientMessages_UnAuthorised,
  DashboardClientMessages_Authorised,
  DashboardClientMessages_Ready,
  DashboardClientName,
  DashboardServiceName,
} from "../def";
import { withSessionToken } from "../../auth-client";

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: unload-dashboard
//-----------------------------------------------------------------------------------------------------------

export async function onUnloadDashboard(messageId: string) {
  // TODO: Add validations here. If they fail, throw an error.
  return {};
}

//-----------------------------------------------------------------------------------------------------------
