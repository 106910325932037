//-----------------------------------------------------------------------------------------------------------
// File: src/components/AuthHandler.tsx
//-----------------------------------------------------------------------------------------------------------

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthClientStates, useAuthClientState } from "../actors/auth-client";
import { isStateChangeFrom } from "../utils/modulo-utils";

//-----------------------------------------------------------------------------------------------------------

const AuthHandler: React.FC = () => {
  const navigate = useNavigate();
  const { state, previousState } = useAuthClientState();

  useEffect(() => {
    if (
      isStateChangeFrom(previousState)
        .to(state)
        .equals(AuthClientStates.Logout_WIP)
        .to(AuthClientStates.UnAuthorised)
    ) {
      navigate("/"); // Navigate to home page on logout
    }
  }, [state, previousState, navigate]);

  return null;
};

//-----------------------------------------------------------------------------------------------------------

export default AuthHandler;

//-----------------------------------------------------------------------------------------------------------
