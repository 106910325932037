//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/dashboard-client/hooks.ts
//-----------------------------------------------------------------------------------------------------------

import { useEffect, useRef, useState } from "react";
import {
  Actor,
  getActor,
  listenTo,
  stopListeningTo,
} from "../../utils/modulo-plus";
import {
  DashboardClientName,
  DashboardClientData_UnAuthorised,
  DashboardClientData_Authorised,
  DashboardClientData_Ready,
  isDashboardClientState_UnAuthorised,
  isDashboardClientState_Authorised,
  isDashboardClientState_FetchDashboard_WIP,
  isDashboardClientState_Ready,
} from "./def";

//-----------------------------------------------------------------------------------------------------------
// Actor
//-----------------------------------------------------------------------------------------------------------

export const useDashboardClientState = () => {
  const [state, setState] = useState(
    () => getActor(DashboardClientName)?.state,
  );
  const previousStateRef = useRef(state);

  useEffect(() => {
    const updateState = (actor: Actor) => {
      previousStateRef.current = state;
      setState(actor.state);
    };
    listenTo(DashboardClientName, updateState);
    return () => stopListeningTo(DashboardClientName, updateState);
  }, [state]);

  return { state, previousState: previousStateRef.current };
};

export const useDashboardClientData = () => {
  const [data, setData] = useState(() => getActor(DashboardClientName)?.data);
  useEffect(() => {
    const updateData = (actor: Actor) => setData(actor.data);
    listenTo(DashboardClientName, updateData);
    return () => stopListeningTo(DashboardClientName, updateData);
  }, []);
  return data;
};

export const useDashboardClientError = () => {
  const [error, setError] = useState(
    () => getActor(DashboardClientName)?.error,
  );
  useEffect(() => {
    const updateData = (actor: Actor) => setError(actor.error);
    listenTo(DashboardClientName, updateData);
    return () => stopListeningTo(DashboardClientName, updateData);
  }, []);
  return error;
};

//-----------------------------------------------------------------------------------------------------------
// State: UnAuthorised
//-----------------------------------------------------------------------------------------------------------

export const useIsDashboardClientState_UnAuthorised = () => {
  const dashboardClientState = useDashboardClientState();
  return isDashboardClientState_UnAuthorised(dashboardClientState.state!);
};

export const useDashboardClientData_UnAuthorised = () => {
  const [authData, setDashboardData] = useState<
    DashboardClientData_UnAuthorised | undefined
  >(() => {
    const actor = getActor(DashboardClientName);
    return actor && isDashboardClientState_UnAuthorised(actor!.state)
      ? (actor.data as DashboardClientData_UnAuthorised)
      : undefined;
  });
  useEffect(() => {
    const handleActorChange = (actor: Actor) => {
      if (isDashboardClientState_UnAuthorised(actor.state)) {
        setDashboardData(actor.data as DashboardClientData_UnAuthorised);
      } else {
        setDashboardData(undefined);
      }
    };

    // Subscribe to changes in the DashboardClient
    listenTo(DashboardClientName, handleActorChange);
    // Cleanup subscription on unmount
    return () => stopListeningTo(DashboardClientName, handleActorChange);
  }, []);
  return authData;
};

//-----------------------------------------------------------------------------------------------------------
// State: Authorised
//-----------------------------------------------------------------------------------------------------------

export const useIsDashboardClientState_Authorised = () => {
  const dashboardClientState = useDashboardClientState();
  return isDashboardClientState_Authorised(dashboardClientState.state!);
};

export const useIsDashboardClientState_FetchDashboard_WIP = () => {
  const dashboardClientState = useDashboardClientState();
  return isDashboardClientState_FetchDashboard_WIP(dashboardClientState.state!);
};

export const useDashboardClientData_Authorised = () => {
  const [authData, setDashboardData] = useState<
    DashboardClientData_Authorised | undefined
  >(() => {
    const actor = getActor(DashboardClientName);
    return actor && isDashboardClientState_Authorised(actor!.state)
      ? (actor.data as DashboardClientData_Authorised)
      : undefined;
  });
  useEffect(() => {
    const handleActorChange = (actor: Actor) => {
      if (isDashboardClientState_Authorised(actor.state)) {
        setDashboardData(actor.data as DashboardClientData_Authorised);
      } else {
        setDashboardData(undefined);
      }
    };

    // Subscribe to changes in the DashboardClient
    listenTo(DashboardClientName, handleActorChange);
    // Cleanup subscription on unmount
    return () => stopListeningTo(DashboardClientName, handleActorChange);
  }, []);
  return authData;
};

//-----------------------------------------------------------------------------------------------------------
// State: Ready
//-----------------------------------------------------------------------------------------------------------

export const useIsDashboardClientState_Ready = () => {
  const dashboardClientState = useDashboardClientState();
  return isDashboardClientState_Ready(dashboardClientState.state!);
};

export const useDashboardClientData_Ready = () => {
  const [authData, setDashboardData] = useState<
    DashboardClientData_Ready | undefined
  >(() => {
    const actor = getActor(DashboardClientName);
    return actor && isDashboardClientState_Ready(actor!.state)
      ? (actor.data as DashboardClientData_Ready)
      : undefined;
  });
  useEffect(() => {
    const handleActorChange = (actor: Actor) => {
      if (isDashboardClientState_Ready(actor.state)) {
        setDashboardData(actor.data as DashboardClientData_Ready);
      } else {
        setDashboardData(undefined);
      }
    };

    // Subscribe to changes in the DashboardClient
    listenTo(DashboardClientName, handleActorChange);
    // Cleanup subscription on unmount
    return () => stopListeningTo(DashboardClientName, handleActorChange);
  }, []);
  return authData;
};

//-----------------------------------------------------------------------------------------------------------
