//-----------------------------------------------------------------------------------------------------------
// File: /src/components/loader/Loader.tsx
//-----------------------------------------------------------------------------------------------------------

import "./Loader.css";

//-----------------------------------------------------------------------------------------------------------

interface LoaderProps {
  text: string;
  isFullWidth?: boolean;
  isUppercase?: boolean;
}

//-----------------------------------------------------------------------------------------------------------

function Loader({ text, isFullWidth, isUppercase }: LoaderProps) {
  return (
    <div className="loader-container">
      <span
        className={`loader-text has-text-info is-size-7 ${isFullWidth ? "is-fullwidth" : ""} ${isUppercase ? "is-uppercase" : ""}`}
      >
        {text}
      </span>
    </div>
  );
}

//-----------------------------------------------------------------------------------------------------------

export default Loader;

//-----------------------------------------------------------------------------------------------------------
