//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/user-client/hooks.ts
//-----------------------------------------------------------------------------------------------------------

import { useEffect, useRef, useState } from "react";
import {
  Actor,
  getActor,
  listenTo,
  stopListeningTo,
} from "../../utils/modulo-plus";
import {
  UserClientName,
  UserClientData_UnAuthorised,
  UserClientData_Authorised,
  UserClientData_Ready,
  isUserClientState_UnAuthorised,
  isUserClientState_Authorised,
  isUserClientState_LoadUsers_WIP,
  isUserClientState_Ready,
} from "./def";

//-----------------------------------------------------------------------------------------------------------
// Actor
//-----------------------------------------------------------------------------------------------------------

export const useUserClientState = () => {
  const [state, setState] = useState(() => getActor(UserClientName)?.state);
  const previousStateRef = useRef(state);

  useEffect(() => {
    const updateState = (actor: Actor) => {
      previousStateRef.current = state;
      setState(actor.state);
    };
    listenTo(UserClientName, updateState);
    return () => stopListeningTo(UserClientName, updateState);
  }, [state]);

  return { state, previousState: previousStateRef.current };
};

export const useUserClientData = () => {
  const [data, setData] = useState(() => getActor(UserClientName)?.data);
  useEffect(() => {
    const updateData = (actor: Actor) => setData(actor.data);
    listenTo(UserClientName, updateData);
    return () => stopListeningTo(UserClientName, updateData);
  }, []);
  return data;
};

export const useUserClientError = () => {
  const [error, setError] = useState(() => getActor(UserClientName)?.error);
  useEffect(() => {
    const updateData = (actor: Actor) => setError(actor.error);
    listenTo(UserClientName, updateData);
    return () => stopListeningTo(UserClientName, updateData);
  }, []);
  return error;
};

//-----------------------------------------------------------------------------------------------------------
// State: UnAuthorised
//-----------------------------------------------------------------------------------------------------------

export const useIsUserClientState_UnAuthorised = () => {
  const userClientState = useUserClientState();
  return isUserClientState_UnAuthorised(userClientState.state!);
};

export const useUserClientData_UnAuthorised = () => {
  const [authData, setUserData] = useState<
    UserClientData_UnAuthorised | undefined
  >(() => {
    const actor = getActor(UserClientName);
    return actor && isUserClientState_UnAuthorised(actor!.state)
      ? (actor.data as UserClientData_UnAuthorised)
      : undefined;
  });
  useEffect(() => {
    const handleActorChange = (actor: Actor) => {
      if (isUserClientState_UnAuthorised(actor.state)) {
        setUserData(actor.data as UserClientData_UnAuthorised);
      } else {
        setUserData(undefined);
      }
    };

    // Subscribe to changes in the UserClient
    listenTo(UserClientName, handleActorChange);
    // Cleanup subscription on unmount
    return () => stopListeningTo(UserClientName, handleActorChange);
  }, []);
  return authData;
};

//-----------------------------------------------------------------------------------------------------------
// State: Authorised
//-----------------------------------------------------------------------------------------------------------

export const useIsUserClientState_Authorised = () => {
  const userClientState = useUserClientState();
  return isUserClientState_Authorised(userClientState.state!);
};

export const useIsUserClientState_LoadUsers_WIP = () => {
  const userClientState = useUserClientState();
  return isUserClientState_LoadUsers_WIP(userClientState.state!);
};

export const useUserClientData_Authorised = () => {
  const [authData, setUserData] = useState<
    UserClientData_Authorised | undefined
  >(() => {
    const actor = getActor(UserClientName);
    return actor && isUserClientState_Authorised(actor!.state)
      ? (actor.data as UserClientData_Authorised)
      : undefined;
  });
  useEffect(() => {
    const handleActorChange = (actor: Actor) => {
      if (isUserClientState_Authorised(actor.state)) {
        setUserData(actor.data as UserClientData_Authorised);
      } else {
        setUserData(undefined);
      }
    };

    // Subscribe to changes in the UserClient
    listenTo(UserClientName, handleActorChange);
    // Cleanup subscription on unmount
    return () => stopListeningTo(UserClientName, handleActorChange);
  }, []);
  return authData;
};

//-----------------------------------------------------------------------------------------------------------
// State: Ready
//-----------------------------------------------------------------------------------------------------------

export const useIsUserClientState_Ready = () => {
  const userClientState = useUserClientState();
  return isUserClientState_Ready(userClientState.state!);
};

export const useUserClientData_Ready = () => {
  const [authData, setUserData] = useState<UserClientData_Ready | undefined>(
    () => {
      const actor = getActor(UserClientName);
      return actor && isUserClientState_Ready(actor!.state)
        ? (actor.data as UserClientData_Ready)
        : undefined;
    },
  );
  useEffect(() => {
    const handleActorChange = (actor: Actor) => {
      if (isUserClientState_Ready(actor.state)) {
        setUserData(actor.data as UserClientData_Ready);
      } else {
        setUserData(undefined);
      }
    };

    // Subscribe to changes in the UserClient
    listenTo(UserClientName, handleActorChange);
    // Cleanup subscription on unmount
    return () => stopListeningTo(UserClientName, handleActorChange);
  }, []);
  return authData;
};

//-----------------------------------------------------------------------------------------------------------
