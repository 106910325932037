//-----------------------------------------------------------------------------------------------------------
// File: /src/screens/users/Users.tsx
//-----------------------------------------------------------------------------------------------------------

import Header from "../../components/header/Header";
import "./Users.css";

//-----------------------------------------------------------------------------------------------------------

function Users() {
  return (
    <>
      <Header />
    </>
  );
}

//-----------------------------------------------------------------------------------------------------------

export default Users;

//-----------------------------------------------------------------------------------------------------------
