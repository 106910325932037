//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/dashboard-client/states/authorised.ts
//-----------------------------------------------------------------------------------------------------------

import { sendMessage, Message } from "../../../utils/modulo-plus";
import {
  DashboardClientData_UnAuthorised,
  DashboardClientData_Authorised,
  DashboardClientData_Ready,
  DashboardClientMessages_UnAuthorised,
  DashboardClientMessages_Authorised,
  DashboardClientMessages_Ready,
  DashboardClientName,
  DashboardServiceName,
} from "../def";
import { withSessionToken } from "../../auth-client";

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: fetch-dashboard
//-----------------------------------------------------------------------------------------------------------

export async function onFetchDashboard(messageId: string) {
  // TODO: Add validations here. If they fail, throw an error.

  sendMessage(
    withSessionToken({
      id: messageId,
      name: DashboardClientMessages_Authorised.FetchDashboard,
      payload: {},
      from: DashboardClientName,
      to: DashboardServiceName,
      confidential: false,
    }),
  );
}

export async function onFetchDashboardSuccess(
  data: DashboardClientData_Ready,
  messageId: string,
): Promise<DashboardClientData_Ready> {
  // TODO: Add any post-processing of data if required.
  return data;
}

export async function onFetchDashboardFailure(
  error: string,
  messageId: string,
): Promise<string> {
  // TODO: Add any post-processing of the error if required.
  return error;
}

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: auth-logout
//-----------------------------------------------------------------------------------------------------------

export async function onAuthLogout(messageId: string) {
  // TODO: Add validations here. If they fail, throw an error.
  return {};
}

//-----------------------------------------------------------------------------------------------------------
