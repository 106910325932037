// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: /src/components/header/Header.css */

.header {
  padding: 10px;
  border-bottom: 0.5px solid black;
}

.header-button {
  margin-top: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/Header.css"],"names":[],"mappings":"AAAA,4CAA4C;;AAE5C;EACE,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["/* File: /src/components/header/Header.css */\n\n.header {\n  padding: 10px;\n  border-bottom: 0.5px solid black;\n}\n\n.header-button {\n  margin-top: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
