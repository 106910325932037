//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/user-client/service-stub.ts
//-----------------------------------------------------------------------------------------------------------

import {
  Actor,
  ActorMeta,
  MessageResult,
  Message,
  sendMessage,
} from "../../utils/modulo-plus";
import { afterDelayOf } from "../../utils/exec";
import {
  UserClientMessages_UnAuthorised,
  UserClientMessages_Authorised,
  UserClientMessages_Ready,
  UserClientName,
  UserServiceName,
} from "./def";

//-----------------------------------------------------------------------------------------------------------
// Constructor
//-----------------------------------------------------------------------------------------------------------

export const createUserServiceStub = (
  actor?: Actor,
  config: Record<string, any> = {},
): Actor & ActorMeta =>
  actor
    ? { ...actor, messageHandler, config }
    : {
        name: UserServiceName,
        state: "ready",
        data: {},
        messageHandler,
        config,
      };

//-----------------------------------------------------------------------------------------------------------
// Message Handler
//-----------------------------------------------------------------------------------------------------------

const messageHandler = async (
  message: Message,
  actor: Actor,
): Promise<Actor> => {
  afterDelayOf(1, () => {
    const { name: messageName, payload, from, to } = message;

    if (!from || !to) {
      console.error(`Message missing 'from' or 'to' fields:`, message);
      return;
    }

    const replyBase = {
      from: to,
      to: from,
      confidential: message.confidential,
    };

    switch (messageName) {
      case UserClientMessages_Authorised.LoadUsers:
        {
          const {} = payload;
          const throwError = false;
          if (!throwError)
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Success}`,
              payload: {
                users: [
                  {
                    id: "u1",
                    "full-name": "Manoj Kumar A",
                    phone: "8880744612",
                    email: "manoj@iriplco.com",
                    role: "super-admin",
                    github: "artvnn",
                  },
                  {
                    id: "u2",
                    "full-name": "Bifin Babu",
                    phone: "8880744612",
                    email: "bifin@iriplco.com",
                    role: "standard",
                    github: "bifinbabu",
                  },
                ],
              },
            });
          else
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Failure}`,
              payload: "Unable to load growth targets",
            });
        }
        break;

      default:
        sendMessage({
          ...replyBase,
          name: `${messageName}-${MessageResult.Failure}`,
          payload: "Invalid message",
        });
    }
  });

  return actor;
};

//-----------------------------------------------------------------------------------------------------------
