import { AppConfiguration } from "./def";

const env = process.env.REACT_APP_ENV || "development";

let config: AppConfiguration;

switch (env) {
  case "production":
    config = require("./config.production").config;
    break;
  case "development":
  default:
    config = require("./config.development").config;
    break;
}

export { config };
export * from "./def";
