// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: /src/screens/home/Home.css */

/* Apply a single border to the table and its cells */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid #ddd;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}

td table {
  width: 100%;
  margin-top: 10px;
}

td table th,
td table td {
  border: 1px solid #ddd;
  padding: 4px;
}

td table th {
  background-color: #e9e9e9;
}
`, "",{"version":3,"sources":["webpack://./src/screens/home/Home.css"],"names":[],"mappings":"AAAA,qCAAqC;;AAErC,qDAAqD;AACrD;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* File: /src/screens/home/Home.css */\n\n/* Apply a single border to the table and its cells */\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-bottom: 20px;\n  border: 1px solid #ddd;\n}\n\nth,\ntd {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\nth {\n  background-color: #f2f2f2;\n  text-align: left;\n}\n\ntd table {\n  width: 100%;\n  margin-top: 10px;\n}\n\ntd table th,\ntd table td {\n  border: 1px solid #ddd;\n  padding: 4px;\n}\n\ntd table th {\n  background-color: #e9e9e9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
