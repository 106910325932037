//-----------------------------------------------------------------------------------------------------------
// File: /src/screens/kanban/Kanban.tsx
//-----------------------------------------------------------------------------------------------------------

import Header from "../../components/header/Header";
import "./Kanban.css";

//-----------------------------------------------------------------------------------------------------------

function Kanban() {
  return (
    <>
      <Header />
    </>
  );
}

//-----------------------------------------------------------------------------------------------------------

export default Kanban;

//-----------------------------------------------------------------------------------------------------------
