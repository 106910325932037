//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/dashboard-client/service-stub.ts
//-----------------------------------------------------------------------------------------------------------

import {
  Actor,
  ActorMeta,
  MessageResult,
  Message,
  sendMessage,
} from "../../utils/modulo-plus";
import { afterDelayOf } from "../../utils/exec";
import {
  DashboardClientMessages_UnAuthorised,
  DashboardClientMessages_Authorised,
  DashboardClientMessages_Ready,
  DashboardClientName,
  DashboardServiceName,
} from "./def";

//-----------------------------------------------------------------------------------------------------------
// Constructor
//-----------------------------------------------------------------------------------------------------------

export const createDashboardServiceStub = (
  actor?: Actor,
  config: Record<string, any> = {},
): Actor & ActorMeta =>
  actor
    ? { ...actor, messageHandler, config }
    : {
        name: DashboardServiceName,
        state: "ready",
        data: {},
        messageHandler,
        config,
      };

//-----------------------------------------------------------------------------------------------------------
// Message Handler
//-----------------------------------------------------------------------------------------------------------

const messageHandler = async (
  message: Message,
  actor: Actor,
): Promise<Actor> => {
  afterDelayOf(1, () => {
    const { name: messageName, payload, from, to } = message;

    if (!from || !to) {
      console.error(`Message missing 'from' or 'to' fields:`, message);
      return;
    }

    const replyBase = {
      from: to,
      to: from,
      confidential: message.confidential,
    };

    switch (messageName) {
      case DashboardClientMessages_Authorised.FetchDashboard:
        {
          const {} = payload;
          const throwError = false;
          if (!throwError)
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Success}`,
              payload: {
                users: [
                  {
                    id: "u1",
                    fullName: "Manoj",
                    jobs: [
                      {
                        id: "j1",
                        name: "Job 1",
                        plannedTime: 3.5,
                        project: { id: "p1", name: "Project 1" },
                      },
                    ],
                  },
                  {
                    id: "u2",
                    fullName: "Bifin",
                    jobs: [
                      {
                        id: "j1",
                        name: "Job 2",
                        plannedTime: 1,
                        project: { id: "p1", name: "Project 2" },
                      },
                    ],
                  },
                ],
              },
            });
          else
            sendMessage({
              ...replyBase,
              name: `${messageName}-${MessageResult.Failure}`,
              payload: "Could not fetch user summary data",
            });
        }
        break;

      default:
        sendMessage({
          ...replyBase,
          name: `${messageName}-${MessageResult.Failure}`,
          payload: "Invalid message",
        });
    }
  });

  return actor;
};

//-----------------------------------------------------------------------------------------------------------
