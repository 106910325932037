// File: /src/screens/home/Home.js

import "./Home.css";
import Header from "../../components/header/Header";
import {
  DashboardClientStates,
  fetchDashboard,
  unloadDashboard,
  useDashboardClientData_Ready,
  useDashboardClientState,
  useIsDashboardClientState_Authorised,
  useIsDashboardClientState_FetchDashboard_WIP,
  useIsDashboardClientState_Ready,
} from "../../actors/dashboard-client";
import { useEffect } from "react";
import { useIsAuthClientState_Authorised } from "../../actors/auth-client";
import { hasReachedState, isStateChangeFrom } from "../../utils/modulo-utils";

function Home() {
  const isDashboard_Ready = useIsDashboardClientState_Ready();
  const isDashboard_FetchDashboard_WIP =
    useIsDashboardClientState_FetchDashboard_WIP();
  const dashboardData = useDashboardClientData_Ready();

  const { previousState, state } = useDashboardClientState();
  useEffect(() => {
    if (
      hasReachedState(DashboardClientStates.Authorised)
        .fromAnyState()
        .values(state, previousState)
    ) {
      fetchDashboard();
    }
  }, [previousState, state]);

  return (
    <div>
      <Header />
      {isDashboard_FetchDashboard_WIP && <>Loading...</>}
      {isDashboard_Ready && (
        <>
          {dashboardData && (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Jobs In Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData.users.map((user) => (
                    <tr key={user.id}>
                      <td>{user.fullName}</td>
                      <td>
                        <table>
                          <thead>
                            <tr>
                              <th>Job Name</th>
                              <th>Project Name</th>
                              <th>Planned Time (hours)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user.jobs.map((job) => (
                              <tr key={job.id}>
                                <td>{job.name}</td>
                                <td>{job.project.name}</td>
                                <td>
                                  {job.plannedHours ? job.plannedHours : "N/A"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {!dashboardData && <>No dashboard data.</>}
          <button onClick={unloadDashboard}>Reload</button>
        </>
      )}
    </div>
  );
}

export default Home;
