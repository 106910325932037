// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: /src/screens/login/Login.css */

.centered-title {
  justify-content: center !important; /* Centers the text in the flex container */
}

.i-realities-logo {
  background-color: white;
  border-radius: 200px;
}

.login-form-container {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/login/Login.css"],"names":[],"mappings":"AAAA,uCAAuC;;AAEvC;EACE,kCAAkC,EAAE,2CAA2C;AACjF;;AAEA;EACE,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":["/* File: /src/screens/login/Login.css */\n\n.centered-title {\n  justify-content: center !important; /* Centers the text in the flex container */\n}\n\n.i-realities-logo {\n  background-color: white;\n  border-radius: 200px;\n}\n\n.login-form-container {\n  margin-left: 10px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
