//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/auth-client/states/authorised.ts
//-----------------------------------------------------------------------------------------------------------

import { sendMessage, Message } from "../../../utils/modulo-plus";
import {
  AuthClientData_UnAuthorised,
  AuthClientData_Authorised,
  AuthClientData_Expired,
  AuthClientMessages_UnAuthorised,
  AuthClientMessages_Authorised,
  AuthClientMessages_Expired,
  AuthClientName,
  AuthServiceName,
} from "../def";
import { withSessionToken } from "../../auth-client";

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: logout
//-----------------------------------------------------------------------------------------------------------

export async function onLogout(messageId: string) {
  // TODO: Add validations here. If they fail, throw an error.

  sendMessage(
    withSessionToken({
      id: messageId,
      name: AuthClientMessages_Authorised.Logout,
      payload: {},
      from: AuthClientName,
      to: AuthServiceName,
      confidential: false,
    }),
  );
}

export async function onLogoutSuccess(
  data: AuthClientData_UnAuthorised,
  messageId: string,
): Promise<AuthClientData_UnAuthorised> {
  // TODO: Add any post-processing of data if required.
  return data;
}

export async function onLogoutFailure(
  error: string,
  messageId: string,
): Promise<string> {
  // TODO: Add any post-processing of the error if required.
  return error;
}

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: expired
//-----------------------------------------------------------------------------------------------------------

export async function onExpired(messageId: string) {
  // TODO: Add validations here. If they fail, throw an error.
  return {};
}

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: change-password
//-----------------------------------------------------------------------------------------------------------

export async function onChangePassword(
  currentPassword: string,
  newPassword: string,
  messageId: string,
) {
  // TODO: Add validations here. If they fail, throw an error.

  sendMessage(
    withSessionToken({
      id: messageId,
      name: AuthClientMessages_Authorised.ChangePassword,
      payload: {
        currentPassword,
        newPassword,
      },
      from: AuthClientName,
      to: AuthServiceName,
      confidential: false,
    }),
  );
}

export async function onChangePasswordSuccess(
  data: AuthClientData_Authorised,
  messageId: string,
): Promise<AuthClientData_Authorised> {
  // TODO: Add any post-processing of data if required.
  return data;
}

export async function onChangePasswordFailure(
  error: string,
  messageId: string,
): Promise<string> {
  // TODO: Add any post-processing of the error if required.
  return error;
}

//-----------------------------------------------------------------------------------------------------------
