//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/user-client/states/un-authorised.ts
//-----------------------------------------------------------------------------------------------------------

import { sendMessage, Message } from "../../../utils/modulo-plus";
import {
  UserClientData_UnAuthorised,
  UserClientData_Authorised,
  UserClientData_Ready,
  UserClientMessages_UnAuthorised,
  UserClientMessages_Authorised,
  UserClientMessages_Ready,
  UserClientName,
  UserServiceName,
} from "../def";
import { withSessionToken } from "../../auth-client";

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: auth-login
//-----------------------------------------------------------------------------------------------------------

export async function onAuthLogin(messageId: string) {
  // TODO: Add validations here. If they fail, throw an error.
  return {};
}

//-----------------------------------------------------------------------------------------------------------
