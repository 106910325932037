//-----------------------------------------------------------------------------------------------------------
// File: /src/actors/auth-client/states/expired.ts
//-----------------------------------------------------------------------------------------------------------

import { sendMessage, Message } from "../../../utils/modulo-plus";
import {
  AuthClientData_UnAuthorised,
  AuthClientData_Authorised,
  AuthClientData_Expired,
  AuthClientMessages_UnAuthorised,
  AuthClientMessages_Authorised,
  AuthClientMessages_Expired,
  AuthClientName,
  AuthServiceName,
} from "../def";
import { withSessionToken } from "../../auth-client";

//-----------------------------------------------------------------------------------------------------------
// MESSAGE: reset
//-----------------------------------------------------------------------------------------------------------

export async function onReset(messageId: string) {
  // TODO: Add validations here. If they fail, throw an error.
  return {};
}

//-----------------------------------------------------------------------------------------------------------
