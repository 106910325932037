//-----------------------------------------------------------------------------------------------------------
// File: /src/actors.ts
//-----------------------------------------------------------------------------------------------------------

import { AppEnvironment, config } from "./config";
import { debounce } from "./utils/exec";
import {
  Actor,
  addActor,
  exportActorData,
  listenToAll,
  stopListeningToAll,
  recoverAllActorsFromWIP,
} from "./utils/modulo-plus";

import { createAuthServiceStub } from "./actors/auth-client/service-stub";
import {
  AuthClientName,
  AuthServiceName,
  createAuthClient,
  destroyAuthClient,
} from "./actors/auth-client";

import { createDashboardServiceStub } from "./actors/dashboard-client/service-stub";
import {
  DashboardClientName,
  DashboardServiceName,
  createDashboardClient,
  destroyDashboardClient,
} from "./actors/dashboard-client";

import { createUserServiceStub } from "./actors/user-client/service-stub";
import {
  UserClientName,
  UserServiceName,
  createUserClient,
  destroyUserClient,
} from "./actors/user-client";
import { createRestProxyActor } from "./utils/modulo-rest-proxy";

//-----------------------------------------------------------------------------------------------------------

const { id, environment, version } = config.app;
let savedData: Record<string, any> | null = null;

// Debounced save function
const debouncedSave = debounce(() => {
  if (config.saveActorStates) {
    const actorData = exportActorData();
    const actorDataWithMeta = {
      ...actorData,
      appId: id,
      appEnvironment: environment,
      appVersion: version,
    };
    console.log("APP: SAVING STATE TO LOCAL STORAGE");
    const storageKey = `${id}/${version}/${environment}/actor-data`;
    localStorage.setItem(storageKey, JSON.stringify(actorDataWithMeta));
  }
}, 500); // Adjust the wait time as necessary

const globalListener = (actor: Actor) => {
  debouncedSave();
};

//-----------------------------------------------------------------------------------------------------------

export async function startActors() {
  // Only load actor data if saving/loading is enabled
  if (config.saveActorStates) {
    // Generate the dynamic key for localStorage
    const storageKey = `${id}/${version}/${environment}/actor-data`;

    // Check for saved actor data in local storage
    const savedDataStr = localStorage.getItem(storageKey);
    if (savedDataStr) {
      try {
        savedData = JSON.parse(savedDataStr);
      } catch {
        console.error("Failed to parse saved actor data");
      }
    }

    // If the saved data’s appId, appEnvironment, or appVersion are different, ignore the saved state
    if (
      savedData &&
      (savedData.appId !== id ||
        savedData.appEnvironment !== environment ||
        savedData.appVersion !== version)
    ) {
      savedData = null;
    }
  }

  //-----------------------------------------------------------------------------------------------------------

  // Create stub service actors
  if (config.app.environment === AppEnvironment.Development) {
    addActor(
      createAuthServiceStub(
        savedData?.[AuthServiceName],
        config.actors[AuthServiceName],
      ),
    );
    addActor(
      createDashboardServiceStub(
        savedData?.[DashboardServiceName],
        config.actors[DashboardServiceName],
      ),
    );
    addActor(
      createUserServiceStub(
        savedData?.[UserServiceName],
        config.actors[UserServiceName],
      ),
    );
  }

  //-----------------------------------------------------------------------------------------------------------

  // Create service proxy actors
  addActor(
    createRestProxyActor(AuthServiceName, config.actors[AuthServiceName]),
  );
  addActor(
    createRestProxyActor(
      DashboardServiceName,
      config.actors[DashboardServiceName],
    ),
  );
  addActor(
    createRestProxyActor(UserServiceName, config.actors[UserServiceName]),
  );

  //-----------------------------------------------------------------------------------------------------------

  // Create client actors
  addActor(
    createAuthClient(
      savedData?.[AuthClientName],
      config.actors[AuthClientName],
    ),
  );
  addActor(
    createDashboardClient(
      savedData?.[DashboardClientName],
      config.actors[DashboardClientName],
    ),
  );
  addActor(
    createUserClient(
      savedData?.[UserClientName],
      config.actors[UserClientName],
    ),
  );

  listenToAll(globalListener);

  recoverAllActorsFromWIP();
}

//-----------------------------------------------------------------------------------------------------------

export async function stopActors() {
  // Destroy actors
  destroyAuthClient();
  destroyDashboardClient();
  destroyUserClient();

  stopListeningToAll(globalListener);
}

//-----------------------------------------------------------------------------------------------------------
