//-----------------------------------------------------------------------------------------------------------
// File: /src/config/config.development.ts
//-----------------------------------------------------------------------------------------------------------

import {
  AuthClientMessages_UnAuthorised,
  AuthClientMessages_Authorised,
  AuthClientMessages_Expired,
  AuthClientName,
  AuthServiceName,
} from "../actors/auth-client";
import {
  DashboardClientMessages_UnAuthorised,
  DashboardClientMessages_Authorised,
  DashboardClientMessages_Ready,
  DashboardClientName,
  DashboardServiceName,
} from "../actors/dashboard-client";
import {
  UserClientMessages_UnAuthorised,
  UserClientMessages_Authorised,
  UserClientMessages_Ready,
  UserClientName,
  UserServiceName,
} from "../actors/user-client";
import { AppConfiguration, AppEnvironment } from "./def";

//-----------------------------------------------------------------------------------------------------------

export const config: AppConfiguration = {
  app: {
    id: "tritiya",
    name: "Tritiya",
    version: "1.0.0",
    environment: AppEnvironment.Development,
  },
  saveActorStates: true,
  actors: {
    [AuthClientName]: {
      name: AuthClientName,
      type: "client",
      isAuthManager: true,
      sessionExpiredMessageName: "expired",
    },

    [DashboardClientName]: {
      name: DashboardClientName,
      type: "client",
      isAuthManager: false,
    },

    [UserClientName]: {
      name: UserClientName,
      type: "client",
      isAuthManager: false,
    },

    [AuthServiceName]: {
      name: AuthServiceName,
      type: "service-rest",
      isAuthManager: true,
      endPoint: "https://tritiya.irealities.com/api/modulo",
      httpMethod: {
        // State: UnAuthorised
        [AuthClientMessages_UnAuthorised.Login]: "POST",

        // State: Authorised
        [AuthClientMessages_Authorised.Logout]: "DELETE",
        [AuthClientMessages_Authorised.ChangePassword]: "PUT",

        // State: Expired
      },
      httpHeaders: {},
      timeout: 5000,
    },

    [DashboardServiceName]: {
      name: DashboardServiceName,
      type: "service-rest",
      isAuthManager: false,
      endPoint: "https://tritiya.irealities.com/api/modulo",
      httpMethod: {
        // State: UnAuthorised

        // State: Authorised
        [DashboardClientMessages_Authorised.FetchDashboard]: "GET",

        // State: Ready
      },
      httpHeaders: {},
      timeout: 5000,
    },

    [UserServiceName]: {
      name: UserServiceName,
      type: "service-rest",
      isAuthManager: false,
      endPoint: "https://tritiya.irealities.com/api/modulo",
      httpMethod: {
        // State: UnAuthorised

        // State: Authorised
        [UserClientMessages_Authorised.LoadUsers]: "POST",

        // State: Ready
      },
      httpHeaders: {},
      timeout: 5000,
    },
  },
};

//-----------------------------------------------------------------------------------------------------------
